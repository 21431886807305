<template>
    <div class="userFormDefined">
        <div class="left-nav">
            <div class="nav">
                <div class="title verticalCenter">
                    <i class="iconfont icon-mendianshebeiliebiao"></i> 门店设备列表
                </div>
                <div class="li" 
                v-for="(item,index) in equipmentData" :key="index"
                :class="{selected:currentIndex==index}" 
                @click="cheageCurrent(index)"
                ><i class="iconfont icon-shebei"></i>{{item.Name}}
                    <i class="iconfont icon-gou-fine"></i>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="footer-box">
                <edit-btns class="btn-box" 
                    :bnts="['save',{key:'copy',text:'复制',click:()=>{onCopy()}},'goBack']"
                    @saveClick="save()"
                    @goBackClick="$router.back()"/>
            </div>
            <div class="flex">
                <div class="region-box">
                    <div class="content">
                        <div class="title"><i class="iconfont icon-zidingyiquyu"></i>自定义区域</div>
                        <div class="li" :class="{selected:groupIndex==index}" v-for="(item,index) in groupDic" :key="index" 
                        @click="cheageGroup(index)"> 
                            {{item.FuncGroup_Name}}
                        </div>
                    </div>
                    <div class="bottom-box">
                        <div class="bnts">
                            <div class="bnt-fangxiang"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="right-content">
                    <div class="top-nav">
                        <div class="title">
                            <div class="name"><i class="iconfont icon-xitongmorenanniu"></i>系统默认按钮</div>
                            <div class="float-right page-box">
                                <div class="bnt-fangxiang" ref="nav-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="nav-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                        <div class="content" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'y',liClass:'.li',isRow:false,paddingH:20,marginH:10}">
                            <div class="li nowrap" :class="{selected:arrTableIds[item.Menu_Key]}"  v-for="item in posMenuList" :key="item" @click="add(item)">
                                {{item.Menu_Name}}
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="title">
                            <div class="name"><i class="iconfont icon-zidingyi"></i>自定义按钮</div>
                        </div>
                        <div class="table-box">
                            <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                highlight-current-row
                                :data="tableData"
                            >
                                <el-table-column fixed label="序号" width="50" type="index" />
                                <el-table-column prop="Menu_Name" label="按钮名称" min-width="100"></el-table-column>
                                <el-table-column label="快捷键" min-width="100" >
                                    <template #default="scope">
                                        <input class="shortcutKey" type="text" v-model="scope.row.Menu_KeyBoard" @keydown="(e)=>keyup(e,scope.row)" readonly/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序" width="150" >
                                    <template #default="scope">
                                        <span class="orderBnt" @click="moveUp(scope.$index)"><i class="iconfont icon-shangsheng"></i></span>
                                        <span class="orderBnt" @click="moveDown(scope.$index)"><i class="iconfont icon-xiajiang"></i></span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="70">
                                    <template #default="scope">
                                        <el-button @click="del(scope.$index)" type="text" size="small">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <modal class="copyModal" :isShow="isCopyShow">
            <div class="header">自定义界面—复制</div>
            <div class="modal-body">
                <div class="title">
                    <div class="lable-txt">源POS：</div>
                    <div class="from-input">
                        <el-select v-model="equipmentAutoID" placeholder="请选择">
                            <el-option :value="item.AutoID" :label="item.Name" v-for="item in equipmentData" :key="item"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="content-box">
                    <div class="title-name">目标POS</div>
                    <div class="scroll-box">
                        <div class="li verticalCenter" :class="{selected:copyEquipment[item.AutoID]}" v-for="item in targetEquipment" :key="item" @click="copyEquipment[item.AutoID]=!copyEquipment[item.AutoID]">
                            <div class="name"><span class=" nowrap2">{{item.Name}}</span></div>
                            <i class="iconfont icon-gou-fine"></i>
                        </div>
                    </div>
                    <div class="bottom-box">
                        <div class="bnts">
                            <div class="bnt-fangxiang"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn btn-cancel" @click="isCopyShow=false">取消</button>
                <button class="btn btn-confirm" @click="confirm()">确定</button>
            </div>
        </modal>
    </div>
</template>

<script>
import {deepClone} from '../../../../common'
export default {
    name:"userFormDefined",
    data(){
        return {
            //复制弹层
            isCopyShow:false,
            equipmentData:[],
            currentIndex:0,
            groupIndex:0,
            tableData:[],
            //是否有修改的数据
            isEditChange:false,
            //设备信息
            equipmentAutoID:"",
            copyEquipment:{}
        };
    },
    computed:{
        targetEquipment(){
            let arr=[];
            this.equipmentData.forEach(it=>{
                if(it.AutoID!=this.equipmentAutoID){
                    arr.push({
                        AutoID:it.AutoID,
                        Name:it.Name
                    })
                }
            });
            return arr;
        },
        groupDic(){
            if(this.equipmentData && this.currentIndex>=0){
                return deepClone(this.equipmentData[this.currentIndex]?.PosFuncGroupDictionaryList||[]);
            }
            return [];
        },
        posMenuList(){
            if(this.equipmentData && this.groupIndex>=0){
                return this.groupDic[this.groupIndex]?.PosMenuList;
            }
            return [];
        },
        arrTableIds(){
            let data={};
            this.tableData.forEach(t=>{
                data[t.Menu_Key]=true;
            });
            return data;
        }
    },
    watch:{
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "加载界面布局设置数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPosSysEquipmentButtonInfo",{ }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.equipmentData=d.ResponseBody||[];
                    this.$nextTick(()=>{
                        this.tableData=this.getDefaultTable();
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到界面布局设置数据：'+e);
                console.log('未找到界面布局设置数据：'+e);
            })
        },
        cheageCurrent(index){
            if(this.currentIndex==index) return;
            if(!this.isEditChange){
                this.currentIndex=index;
                this.cheageGroup(0,true);
            }else{
                this.$confirm('数据已修改是否先保存', "提示", {
                    confirmButtonText: "是",
                    cancelButtonText:"否",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.save();
                        }else{
                            this.isEditChange=false;
                            this.cheageCurrent(index);
                        }
                    },
                });
            }
        },
        cheageGroup(index,isbo){
            if(!isbo && this.groupIndex==index) return;
            if(!this.groupDic[this.groupIndex].DiyFuncButtonList){
                this.groupDic[this.groupIndex].DiyFuncButtonList=[];
            }
            this.groupIndex=index;
            this.tableData=this.getDefaultTable();
        },
        getDefaultTable(){
            if(!this.groupDic[this.groupIndex]?.DiyFuncButtonList || this.groupDic[this.groupIndex]?.DiyFuncButtonList.length==0){
                let equipment=this.equipmentData[this.currentIndex];
                let group=this.groupDic[this.groupIndex];
                this.groupDic[this.groupIndex].DiyFuncButtonList=this.posMenuList.map(item=>{
                    return {
                        Dict_AutoID:group.Dict_AutoID,
                        PosEqment_AutoID:equipment.AutoID,
                        FuncGroup_Key:group.FuncGroup_Key,
                        Menu_Key:item.Menu_Key,
                        Menu_Name:item.Menu_Name,
                        Menu_KeyBoard:""
                    }
                })
            }
            return this.groupDic[this.groupIndex].DiyFuncButtonList;
            
        },
        //删除
        del(index){
            this.tableData.splice(index,1);
            this.isEditChange=true;
        },
        //添加按钮
        add(item){
            if(this.arrTableIds[item.Menu_Key]){
                let index=this.tableData.findIndex(t=>item.Menu_Key==t.Menu_Key);
                this.del(index);
            }else{
                let equipment=this.equipmentData[this.currentIndex];
                let group=this.groupDic[this.groupIndex];
                this.tableData.push({
                    Dict_AutoID:group.Dict_AutoID,
                    PosEqment_AutoID:equipment.AutoID,
                    FuncGroup_Key:group.FuncGroup_Key,
                    Menu_Key:item.Menu_Key,
                    Menu_Name:item.Menu_Name,
                    Menu_KeyBoard:""
                })
            }
            this.isEditChange=true;
        },
        //上移
        moveUp(index){
            if(index>0){
                let item=this.tableData[index];
                this.tableData.splice(index, 1);
                this.tableData.splice(index-1,0, item);
                this.isEditChange=true;
            }
        },
        //下移
        moveDown(index){
            if(index<this.tableData.length-1){
                let item=this.tableData[index];
                this.tableData.splice(index, 1);
                this.tableData.splice(index+1,0, item);
                this.isEditChange=true;
            }
        },
        keyup(e,data){
            e.preventDefault();
            if(e.key== "Backspace"){
                data.Menu_KeyBoard="";
            }
            else if (e.key!= "Control" && e.key!= "Alt" && e.key!= "Shift") {
                let str="";
                //组合键 Ctrl+Shift+Alt+
                if(e.ctrlKey){
                    str+="Ctrl+";
                }
                if(e.shiftKey){
                    str+="Shift+";
                }
                if(e.altKey){
                    str+="Alt+";
                }

                if(e.keyCode==32){
                    data.Menu_KeyBoard=str+"Space".toLocaleUpperCase();
                }else if(e.key=="ArrowUp"){
                    data.Menu_KeyBoard=str+"↑";
                }else if(e.key=="ArrowDown"){
                    data.key=str+"↓";
                }else if(e.key=="ArrowLeft"){
                    data.Menu_KeyBoard=str+"←";
                }
                else if(e.key=="ArrowRight"){
                    data.Menu_KeyBoard=str+"→";
                }
                else{
                    data.Menu_KeyBoard=str+e.key.toLocaleUpperCase();
                }
            }else if(e.key== "Control"){
                data.Menu_KeyBoard="Ctrl";
            }else if(e.Menu_KeyBoard== "Alt"){
                data.Menu_KeyBoard="Alt";
            }else if(e.key== "Shift"){
                data.Menu_KeyBoard="Shift";
            }
            this.isEditChange=true;
        },
        //点击复制
        onCopy(){
            if(this.isEditChange){
                this.$message.warning("数据已修改,请先保存在进行复制操作!");
                return;
            }

            this.isCopyShow=true;
            this.copyEquipment={};
            let equipment=this.equipmentData[this.currentIndex];
            this.equipmentAutoID=equipment.AutoID;
        },
        confirm(){
            let equipmentIds=[];
            this.targetEquipment.forEach(it=>{
                if(this.copyEquipment[it.AutoID]){
                    equipmentIds.push(it.AutoID);
                }
            });
            if(equipmentIds.length==0){
                this.$message.warning("请选择目标POS!");
                return;
            }
            let data= this.equipmentData.find(it=>it.AutoID==this.equipmentAutoID);
            if(!data){
                this.$message.warning("请选择源POS!");
                return;
            }
            let infoList=data.PosFuncGroupDictionaryList;
            this.$confirm('提示：您确认将源POS【'+data.Name+'】复制到所选POS吗？', "提示", {
                confirmButtonText: "是",
                cancelButtonText:"否",
                callback: (name) => {
                    if(name=='confirm'){
                        this.copySave(equipmentIds,infoList);
                    }
                },
            });
        },
        /**复制 保存*/
        copySave(equipmentIds,infoList){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SaveDiyFuncButtonInfo",{
                EQUIPMENT_IDs:equipmentIds,
                PosFuncGroupDictionaryList:infoList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("复制保存成功");
                    this.isCopyShow=false;
                    this.equipmentData.forEach(it=>{
                        if(equipmentIds.indexOf(it.AutoID)>=0){
                            it.PosFuncGroupDictionaryList=deepClone(infoList);
                        }
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('界面布局设置数据复制保存失败：'+e);
                console.log('界面布局设置数据复制保存失败：'+e);
            })
        },
        /**保存 */
        save(){
            let infoList=this.groupDic;
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let equipment=this.equipmentData[this.currentIndex];
            console.log(infoList)
            this.$httpAES.post("Bestech.CloudPos.SaveDiyFuncButtonInfo",{
                EQUIPMENT_IDs:[equipment.AutoID],
                PosFuncGroupDictionaryList:infoList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    this.isEditChange=false;
                    this.equipmentData[this.currentIndex].PosFuncGroupDictionaryList=infoList;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('界面布局设置数据保存失败：'+e);
                console.log('界面布局设置数据保存失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './userFormDefined.scss'
</style>